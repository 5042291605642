import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@env/environment';
import packageInfo from '../package.json';
import { BrowserTracing, init, instrumentAngularRouting } from '@sentry/angular-ivy';
import type { BrowserOptions } from '@sentry/browser';
import { Integration } from '@sentry/types';

/**
 * Init Sentry connection
 */

export function sentryInit(): void {
  const sentryConfig: BrowserOptions = {
    dsn: environment.SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [environment.app_uri, environment.apiV2, /^\//],
        routingInstrumentation: instrumentAngularRouting,
      }),
    ],
    tracesSampleRate: 1,
    release: `${environment.SENTRY_PROJECT}@${packageInfo.version}`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // add request payload (body) to trace
    // https://stackoverflow.com/questions/56883255/send-payload-data-with-post-put-patch-requests-to-sentry-io
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category === 'xhr') {
        const data = {
          requestBody: hint.xhr.__sentry_xhr__.body
        };

        return { ...breadcrumb, data };
      }

      return breadcrumb;
    }
  }

  if (environment.production) {
    (sentryConfig.integrations as Integration[]).push(
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: true,
        maskAllInputs: true
      })
    );
    // TODO: TT-4395 return with updated options with check for the new users
    // remove replays without errors
    // sentryConfig.replaysSessionSampleRate = 1.0;
    // capture each error in dev/stage modes
    sentryConfig.replaysOnErrorSampleRate = 1.0;
  }

  if (environment.SENTRY_DSN) {
    init(sentryConfig);
  }
}
