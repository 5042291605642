import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ShortNumberPipe } from '@shared/pipe/short-number/short-number.pipe';

/**
 * Custom currency pipe.
 * Transform number into currency
 * Transform negative number into "brackets" currency
 * Transform not-number values into 'N/A'
 *
 * isShort param modified value with ShortNumberPipe
 */

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  constructor(
    private shortNumberPipe: ShortNumberPipe,
    private currencyPipe: CurrencyPipe,
  ) {
  }

  transform(value: any, withoutFraction = false): any {
    // Handle not-number cases and 0 value
    if (value === null) {
      return 'N/A';
    }

    if (typeof value !== 'number') {
      return value;
    }

    value = Number(value);

    if (isNaN(value)) {
      return 'N/A';
    }

    return this.currencyPipe.transform(value, 'USD', 'symbol', withoutFraction ? '1.0-0' : '');
  }
}
