import { Component, Input } from '@angular/core';
import {
  Dictionary,
  ExportDataTable,
  ExportableCollection,
  PdfFromDataTableService,
  PdfOrientationEnum,
  PdfSettings, XlsxService
} from 'taxtank-core';
import { plainToClass } from 'class-transformer';
import { ExportComponent } from '@shared/component/export/export.component';

declare type ViewType = 'button' | 'icon' | 'text';

/**
 * @TODO remove?
 */
@Component({
  selector: 'app-data-table-export',
  templateUrl: './data-table-export.component.html',
  styleUrls: ['./data-table-export.component.scss']
})
export class DataTableExportComponent extends ExportComponent {
  @Input() tables: ExportDataTable[];
  @Input() type: number;
  @Input() label?: string;
  @Input() collection?: ExportableCollection<any>;
  @Input() dictionary?: Dictionary<any>;
  @Input() viewType: ViewType = 'button';
  @Input() orientation: PdfOrientationEnum = PdfOrientationEnum.PORTRAIT;

  constructor(
    private pdfFromDataTableService: PdfFromDataTableService,
    private xlsxService: XlsxService
  ) {
    super();
  }

  export(): void {
    this.pdfFromDataTableService.export(
      this.tables,
      plainToClass(PdfSettings, {title: this.title, filename: this.filename, orientation: this.orientation, subtitle: this.subtitle, description: this.description})
    );
  }

  exportToXLSX() {
    this.xlsxService.exportArrayToExcel(this.collection, this.filename, this.dictionary);
  }
}
