import { Component, Inject, Input, OnChanges } from '@angular/core';
import { ExportComponent } from '@shared/component/export/export.component';
import {
  ExportFormatEnum,
  PdfFromDomElementService,
  PdfFromHtmlTableService,
  PdfSettings,
  XlsxService
} from 'taxtank-core';
import { DOCUMENT } from '@angular/common';
import { plainToClass } from 'class-transformer';

declare type ExportSource = 'table' | 'html';
declare type ViewType = 'gradient' | 'basic';

/**
 * Export HTML elements as PDF file
 */
@Component({
  selector: 'app-html-element-export',
  templateUrl: './html-element-export.component.html',
  styleUrls: ['./html-element-export.component.scss']
})
export class HtmlElementExportComponent extends ExportComponent implements OnChanges{
  /**
   * Export source: HTML Table element or any HTML element.
   * Source is necessary because specific data processing is required before export, depending on the type of HTML
   */
  @Input() source: ExportSource = 'table';
  @Input() buttonClass?: string = 'primary';
  @Input() viewType?: ViewType = 'gradient';
  @Input() customButtonLabel?: string;
  /**
   * container where to look for exportable elements
   */
  @Input() container?: HTMLElement;
  isBasicViewType = false;
  /**
   * Node list of the HTML elements with the 'appExportable' directive
   */
  exportableElements: NodeListOf<HTMLElement>;
  isLoading: boolean;

  constructor(
    private pdfFromDomElementService: PdfFromDomElementService,
    private pdfFromHtmlTableService: PdfFromHtmlTableService,
    private xlsxService: XlsxService,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) {
    super();
  }

  ngOnChanges() {
    if (this.viewType === 'basic') {
      this.isBasicViewType = true;
    }
  }

  /**
   * needs to unwrap tables from p-table
   */
  getTableElements() {
    // create fragment outside the dom
    const fragment = new DocumentFragment();
    // move all with [appExportable] into fragment
    this.exportableElements.forEach(element => fragment.appendChild(element.cloneNode(true)));

    return fragment.querySelectorAll('table');
  }

  export(format?: ExportFormatEnum): void {
    this.exportableElements = (this.container || this.document).querySelectorAll('[appExportable]');

    if (this.source === 'table') {
      this.exportableElements = this.getTableElements();
    }

    if (!this.exportableElements.length) {
      return;
    }

    this.isLoading = true;
    // todo: tt-4333: we need to check what's going on with <mat-table. it can't be exported
    if (this.source === 'table') {
      this.exportTables(format);
    } else {
      // html2canvas freezing the page, so timeout required to show preloader before
      setTimeout(() => this.exportHTML());
    }
  }

  private exportTables(format: ExportFormatEnum): void {
    switch (format) {
      case ExportFormatEnum.PDF:
        this.pdfFromHtmlTableService.export(
          this.exportableElements as NodeListOf<HTMLTableElement>,
          plainToClass(PdfSettings, { title: this.title, filename: this.filename, orientation: this.fileOrientation }));
        break;
      case ExportFormatEnum.XLSX:
        this.xlsxService.exportTables(this.exportableElements as NodeListOf<HTMLTableElement>, this.title, this.filename);
        break;
    }
    this.isLoading = false;
  }

  private exportHTML(): void {
    this.pdfFromDomElementService.download(
      this.exportableElements,
      plainToClass(PdfSettings, { title: this.title, filename: this.filename, orientation: this.fileOrientation })
    ).subscribe(() => {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    });
  }
}
